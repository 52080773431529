<template>
    <v-row no-gutters justify="center" align-content="center" class="pa-5" style="height: 100%;">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <v-progress-circular indeterminate size="32" width="2" color="grey darken-2"/>
                </v-row>
            </template>
            <template v-if="isClientError">
                <v-alert type="error">
                    We cannot process this request.
                </v-alert>
                <p class="text-h5 font-weight-light mt-6">The request was either not found, expired, or may have been created incorrectly.</p>
                <p class="text-body-1 font-weight-light text-center"><router-link :to="{ name: 'front' }">Continue</router-link></p>
            </template>
            <template v-if="isServerError">
                <v-alert type="error">
                    We cannot process this request due to a temporary server error. Reload the page to try again.
                    If it&apos;s not resolved in a few minutes, please <a :href="customerSupportURL" target="_blank">contact customer support</a>.
                </v-alert>
            </template>
        </v-col>
    </v-row>
</template>
<style lang="css">
</style>
<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { ROUTE_LOGIN, ROUTE_SIGNUP, ROUTE_PROFILE, INTENT_LOGIN, INTENT_SIGNUP, INTENT_PROFILE } from '@/sdk/constants';

/*
Overview:
The intent forwarder is useful to redirect the user from one activity to another.
To use it, the sending activity stores the intent name and its parameters in Vuex
and redirects the user here, where we use that information to redirect the user
to the receiving activity.

To keep things simple, we ONLY read the intent parameters from the Vuex store and
we do NOT attempt to read them from the query string.

The advantage of using the intent forwarder is that we can connect multiple steps
together in different ways without having to build repetitive "where to redirect
next" logic into all of them. Every step can simply obtain the intent and parameters
for the next step from somewhere, then redirect here and we'll redirect the user
to the appropriate next step.

When storing the intent and parameters in the Vuex store, there's an advantage
that the intent parameters can be objects with nested properties, and they don't
need to be converted to and from a query string when transitioning between views.

NOTE: Our use of Vuex is a convenience so we can keep the intent and intent
parameters we got from the server separate from the query parameters that may
have fallback info -- it is NOT a security measure to prevent
users from tampering with query parameters. For security against users tampering
with state, use interactions instead and let the server control and modify the
state.
*/

export default {
    components: {
    },
    data: () => ({
        isViewReady: false,
        errorBadRequest: false,
        errorForbidden: false,
        serverError: false,
        nextRoute: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            intentMap: (state) => state.intentMap,
        }),
        isClientError() {
            return this.interactionError || this.errorBadRequest || this.errorForbidden;
        },
        isServerError() {
            return this.serverError;
        },
        customerSupportURL() {
            return process.env.VUE_APP_SUPPORT_URL ?? process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://cryptium.com';
        },
    },
    methods: {
        async init() {
            const intent = this.$route.query.intent; // login, signup, etc.
            if (intent) {
                this.resumeIntent(intent);
                if (this.nextRoute) {
                    console.log(`after resumeIntent with nextRoute: ${JSON.stringify(this.nextRoute)}`);
                    this.$router.replace(this.nextRoute);
                    return;
                }
            }
            this.errorBadRequest = true;
            this.isViewReady = true;
        },
        /*
        getIntentParamsFromQuery(intent) {
            // the list of parameters varies according to the intent
            let list = [];
            switch (intent) {
            case INTENT_LOGIN:
                list = ['email', 'verified_email', 'interaction_id'];
                rename.interaction_id = 'i'; // so URL query will be /login?i=<value> instead of /login?interaction_id=<value>
                break;
            case INTENT_SIGNUP:
                list = ['email', 'verified_email', 'interaction_id', 'product', 'step'];
                rename.interaction_id = 'i'; // so URL query will be /login?i=<value> instead of /login?interaction_id=<value>
                break;
            default:
                break;
            }
            const params = {};
            list.forEach((name) => {
                params[name] = this.$route.query[name];
            });
            return params;
        },
        */
        resumeIntent(intent) {
            // const params = this.getIntentParamsFromQuery(intent);
            const params = this.intentMap[intent];
            switch (intent) {
            case INTENT_PROFILE:
                this.$router.replace({ name: ROUTE_PROFILE });
                break;
            case INTENT_LOGIN: {
                const query = {
                    email: params.email,
                    i: params.interactionId,
                };
                this.$router.replace({ name: ROUTE_LOGIN, query });
                break;
            }
            case INTENT_SIGNUP: {
                const query = {
                    email: params.email,
                    product: params.product,
                    step: params.step ?? 'display_name',
                    i: params.interactionId,
                };
                this.$router.replace({ name: ROUTE_SIGNUP, query });
                break;
            }
            default:
                this.errorBadRequest = true;
                break;
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
